.recentInteractions {
  padding: 4rem;
  background-color: var(--gray-100);
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.recentInteractions h2 {
  text-align: center;
  font-size: var(--fs-xl);
  color: var(--blue-400);
  letter-spacing: 0.06em;
  font-weight: bold;
  margin: 0;
}

@media (max-width: 768px) {
  .recentInteractions {
    padding: 2rem;
    gap: 0.1rem;
  }

  .recentInteractions h2 {
    font-size: var(--fs-xl);
  }
}
