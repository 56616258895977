:root {
  --light-blue-100: #F4FAFF;

  --blue-100: #51a2ca;
  --blue-200: #0080bf;
  --blue-300: #387DA6;
  --blue-400: #184b76;
  --blue-600: #153240;

  --green-100: #57bfa3;
  --green-200: #3ea78b;

  --white-100: #ffffff;
  --white-200: #e0e0e0;
  --white-300: #d5d5d5;
  --white-500: #a0aec0;

  --gray-100: #f9f9f9;
  --gray-200: #e5e5e5;
  --gray-400: #777777;
  --gray-500: #555555;
  --gray-900: #111827;

  --red-100: #e57373;
  --red-300: #f44336;
  --red-600: #d32f2f;

  --fs-4xs: 10px;
  --fs-3xs: 12px;
  --fs-2xs: 14px;
  --fs-xs: 16px;
  --fs-sm: 18px;
  --fs-md: 18px;
  --fs-lg: 20px;
  --fs-xl: 24px;
  --fs-2xl: 30px;
  --fs-3xl: 38px;

  --lh-3xs: 18px;
  --lh-2xs: 21px;
  --lh-xs: 24px;
  --lh-sm: 28px;
  --lh-md: 22px;
  --lh-lg: 24px;
  --lh-xl: 28px;
  --lh-2xl: 36px;
  --lh-3xl: 44px;

  --spacing-3: 0.75rem;
  --spacing-4: 1rem;

  --border-radius-xs: 4px;
  --border-radius-md: 0.5rem;

  --shadow-sm: 0 2px 6px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 12px rgba(0, 0, 0, 0.2);
}
