.searchButtonContainer {
  display: flex;
  align-items: center;
  height: 2.5rem;
  margin: 0 auto;
  width: 100%;
  background-color: white;
  border: none;
  border-radius: var(--border-radius-xs);
  cursor: pointer;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.searchButtonContainer:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.searchButtonSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 0.3rem;
  overflow: hidden;
}

.searchButtonDivider {
  width: 1px;
  height: 20px;
  background-color: #888;
  margin: 0 0.5rem;
}

.searchButtonValue {
  font-size: var(--fs-2xs);
  line-height: var(--lh-2xs);
  font-weight: 600;
  font-style: italic;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: block;
}

.searchIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  margin-right: 0.5rem;
}

.searchIcon {
  color: black;
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
}

.normalText {
  font-weight: 400;
  color: #888;
}

.boldText {
  font-weight: 600;
  color: black;
}

@media (max-width: 480px) {
  .searchButtonValue {
    font-size: var(--fs-3xs);
    line-height: var(--lh-3xs);
  }

  .searchButtonDivider {
    height: 15px;
  }

  .searchIcon {
    font-size: 1rem;
  }
}
