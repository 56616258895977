.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blue-300);
  color: white;
  gap: 1rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.homeSearch {
  display: flex;
  justify-content: center;
  width: 50%;
}

.home h1 {
  font-size: 50px;
  text-shadow: 1px 1px #002338;
  font-family: 'Estudi Font';
}

.homeTitle {
  display: inline;
  align-items: center;
  font-size: var(--fs-2xl);
  line-height: var(--lh-xl);
  font-weight: 700;
  letter-spacing: .06em;
}

.titleHighlight {
  color: var(--green-100);
}

.helpLink {
  font-size: var(--fs-2xs);
  line-height: var(--lh-2xs);
  margin-top: 1rem;
}

.home a {
  color: var(--green-200);
}

.select {
  width: 20vw;
}


@media (max-width: 768px) {
  .homeSearch {
    margin: 0.5rem;
    width: 90%;
  }
}
