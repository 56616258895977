.searchDrawerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.searchDrawerHeader {
  background-color: var(--blue-300);
  width: 94%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
}

.searchDrawerButton {
  width: 80%;
}

.cancelSearch {
  float: right;
  color: white;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  font-weight: 400;
}

.autocompleteField{
  background-color: white;
  border-radius: 4px;
}

.searchDrawerButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  gap: 1rem;
  margin-bottom: 2.5rem;
}

.recentCoursesContainer {
  width: 90%;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  text-align: left;
}

.recentCoursesTitle {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.recentCoursesList {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: calc(100vh - 28rem);;
}

.recentCourseItem {
  font-size: 0.9rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.recentCourseItem:hover {
  color: var(--blue-200);
}