.calendar {
  display: flex;
}

.calendarContainer {
  padding-bottom: 10%;
  width: 100%;
}

.calendarTitle {
  text-align: start;
  font-weight: 100;
  margin-top: 3%;
  margin-bottom: 2%;
}

.calendarButton {
  margin: auto;
  margin-top: 2%;
  margin-bottom: 0;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  border: 0px;
  padding: 10px 25px;
  font-weight: 600;
}

.calendarButtonDisabled {
  cursor: not-allowed;
  background-color: grey;
}

.fc .fc-toolbar-title {
  font-size: 1.15em;
}

.fc .fc-highlight {
  background-color: var(--blue-200)!important;
  opacity: 0.4 !important;
}

.fc-bg.fc-selected {
  background-color: var(--blue-200) !important;
  opacity: 0.4 !important;
}

.radioSlotDuration {
  margin-bottom: 2%;
  display: flex;
  align-items: flex-start;
}

@media (max-width: 1120px) {
  .calendarButton {
    margin: auto;
    margin-top: 4%;
    position: sticky;
    width: 80%;
    bottom: 1.5rem;
    left: 2rem;
    right: 2rem;
    z-index: 2;
  }

  .calendar {
    display: flex;
  }

  .calendar h2 {
    font-size: 1rem;
  }
}
